<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>用户充值协议</h2>
		<p>本充值服务协议（以下称“本协议”）由您与PIX TECHNOLOGY PTE. LTD下的UniLive平台签订；平台将按照本协议约定及不时发布的操作规则向您提供服务（以下称“糖果服务”或“本服务”）。</p>
		<p>为更好地向用户提供服务，请服务使用人（即下单购买“糖果”的账号使用人，以下称“您”）在开始使用本服务前认真阅读并充分理解本协议，其中，免除或限制平台责任的条款、请您重点阅读。</p>
		<p>如您不同意本协议，请您不要以任何形式进行下一步操作（包括但不限于点击充值等操作按钮、进行支付）及使用本服务。</p>
		<p>
			平台禁止未成年人使用糖果服务。平台在此善意提醒，若您为未成年人的监护人，您应对您监护的未成年人承担监护职责，请您在未成年人使用本平台相关产品及服务时开启青少年模式及/或其他未成年人保护工具，监督、指导未成年人正确使用相关产品及服务，同时加强对网络支付方式的限制与管理，共同营造未成年人健康成长的良好环境。
		</p>
		<h3>一、服务内容</h3>
		<p>
			1、“糖果”是平台向您提供的、仅限于在平台内进行相关消费的虚拟工具。您购买“糖果”后，可以按照平台相关页面的说明及指引使用、获得平台商品或服务，包括但不限于直播打赏服务（用户可通过向主播赠送虚拟礼物的方式为主播表演等服务支付对价）等。为免生疑义，糖果服务是用户付费、平台向用户持续提供的整体网络技术及相关服务，糖果服务并非网络支付服务，“糖果”也不是代币票券、虚拟货币或预付款凭证，不具备货币价值、预付价值。
		</p>
		<p>2、您购买“糖果”后仅可将其用于本协议项下的约定目的，不得超出公司提供的产品/服务的范围以外使用，亦不能在不同的UniLive账号之间转移。</p>
		<p>
			3、您应通过平台指定官方渠道购买“糖果”，平台不认可任何未经公司授权的渠道。若您使用非官方渠道购买“糖果”，平台无法保证该等“糖果”能顺利进入您的账号，且该等行为还可能伴随诈骗、洗钱等风险，给您、平台及相关第三方造成难以挽回的损失或损害。因此，非官方渠道购买行为将被视为违规，此时平台有权扣除或清空您账号内的“糖果”、限制您账号全部或部分功能、直至永久封禁您的账号，您应自行承担因此而导致的您的损失；如您违反前述约定的行为造成平台或其他第三方损失的，您应负责赔偿。如您通过平台在天猫等第三方平台开设的官方店铺等渠道购买的，平台将通过您指定的“糖果”发放账号的使用人信息及/或您与公司的另行协议（如有）中的约定认定服务使用人。
		</p>
		<p>
			4、您购买“糖果”的费用将由公司或公司指定的合作方收取。平台特别提示您，不同购买渠道的相关服务商可能会按根据其自身运营策略，在您付费时收渠道服务费，这可能会导致您通过不同渠道购买相同数量“糖果”的费用金额或支付同等金额费用购买的“糖果”数量有所差异，具体以您购买“糖果”时的页面展示为准。请您注意确认相关页面信息并合理选择“糖果”购买渠道。
		</p>
		<p>5、如您拟为他人账号购买“糖果”，平台会将相应数量的“糖果”发放至您指定的账号项下，该账号使用人可依据本协议约定使用平台提供的糖果服务。若因此而产生争议，由您与该账号使用人自行协商解决，平台无需就此向您及该账号使用人承担责任。
		</p>

		<h3>二、理性消费</h3>
		<p>1、平台倡导理性消费、量入为出。请务必根据您的自身消费能力和实际需求，合理购买并使用糖果服务，避免过度消费。</p>
		<p>
			2、您用于购买“糖果”的资金应为您合法获得且有权使用的收入；如您违反本条，因此产生的任何争议或纠纷您应自行解决并承担法律后果，如您的行为给平台或第三方造成损失，您还应当全额赔偿。若平台发现（包括但不限于主动发现、收到第三方投诉或主管部门、司法机关通知等情形）您涉嫌违反前述约定，平台有权扣除或清空您账号内的“糖果”、限制您账号全部或部分功能、直至永久封禁您的账号；同时平台有权保存相关信息，并向相关主管部门、司法机关报告。
		</p>
		<p>
			3、平台严格抵制诱导、刺激、煽动用户非理性打赏以及引诱、教唆未成年人以虚假身份信息打赏的行为。如您发现前述违规行为，您可按照平台公示渠道（直播间右下方-举报、UniLive直播行业自律平台-举报等）向平台举报，平台将依法依规采取处置措施。期待您与我们一起构建健康有序的社区生态。
		</p>


		<h3>三、您的权利义务</h3>
		<p>1、您在使用本服务过程中提供的个人信息或资料应当真实、准确，并应按照法律规定及平台的要求协助和配合监管机构的调查（如需要）。</p>
		<p>
			2、您在购买“糖果”时应谨慎选择及/或输入您的账号信息/账号绑定邮箱、“糖果”购买数量等。若因您自身输入错误、操作不当、未充分了解计费方式等因素造成账号错误、“糖果”数量错误等购买错误情形，因此而导致您的损失或支出，平台有权不作出补偿或赔偿。
		</p>
		<p>3、您应妥善保管、使用您的账号，并对该账号下的一切操作行为及后果负责。如因您以下情形导致平台无法提供本服务或提供本服务时发生错误，则因此而导致您的损失，除法律法规明确要求外平台不承担法律责任：</p>
		<p>（1）您的账号失效、丢失、被盗用、被封禁；</p>
		<p>（2）您账号绑定的第三方支付机构、银行账户被冻结、查封或发生其他异常，或您使用了未经认证的账户或非您本人的账户；</p>
		<p>（3）您将账号密码告知他人或已其他方式允许他人登录使用您的账号；</p>
		<p>（4）您存在故意或重大过失的其他情形。</p>
		<p>
			4、您应当合法合规使用本服务，不得将本服务用于任何违法犯罪、违背公序良俗、有害社会道德风尚或干扰平台正常运营、侵害第三方合法权益的目的，您使用本服务的行为也不得违反任何对您有约束力的文件或其他要求（如有）。平台特别提醒您，请勿外借、转让或以其他方式将您的账号提供给其他人使用，合理防范他人通过您的账号实施违反前述约定的行为，以保护自己的账号及财产安全。
		</p>
		<p>
			5、平台依法依规提供未成年人消费退款服务，以保障未成年人及其监护人的合法权益；您不应以非法目的或不当方式使用该服务，包括但不限于成年人冒充未成年人骗取退款、诱导未成年人消费后申请退款等。前述行为将构成对本协议的严重违反，平台经合理确认后有权拒绝退款，并保留进一步依法追究您法律责任的权利。
		</p>
		<p>
			6、您应承诺不得利用该专属账号进行洗钱等任何非法活动，也不得接受他人利用您通过该专属账号完成洗钱等的任何非法要求。若您违反前述约定，平台有权根据反洗钱相关法律规定及/或主管部门、司法机关要求采取暂停提供服务、展开调查等措施，且无须承担您因此所遭受的任何损失或损害。
		</p>
		<p>
			7、您在使用本服务过程中如涉及由第三方提供的相关服务（例如支付服务等），则除遵守本协议约定外，您还需要同意并遵守该等第三方的协议及相关规则，任何情况下，对于该第三方及其提供的相关服务而产生的争议由您自行与该第三方解决，平台无需就此向您或该第三方承担任何责任。
		</p>


		<h3>四、平台权利义务</h3>
		<p>
			1、平台有权基于法律法规修订、监管部门要求、交易安全保障、运营策略更新、市场环境变化等方面的考虑，不时对“糖果”服务设置相关限制、提醒等，包括但不限于限制全部或部分用户的交易限额及/或交易次数、禁止特定用户使用本服务、或增加交易验证等。
		</p>
		<p>
			2、为保障交易安全，平台还有权对您使用本服务的行为进行监测，对因此合理认定为风险较高的用户或账号，平台可能采取必要措施，以防范风险扩大、保障用户财产和平台生态安全；该等必要措施包括扣除或清空您账号内的“糖果”、限制您账号全部或部分功能、短期或永久封禁您的账号等。
		</p>
		<p>
			3、当平台发现因系统故障或其他任何原因导致的处理错误，无论有利于平台还是有利于您，平台都有权纠正该错误。此时，如您实际收到的“糖果”数量少于您应获得的“糖果”，则平台在确认该处理错误后会尽快将差额补足至您的账号；如您实际收到的“糖果”数量多于您应获得的“糖果”，平台将有权不经提前通知而从您的账号直接扣除差额。
		</p>
		<p>4、平台有权视交易安全、运营规划、国家法律法规或监管部门要求等具体情况，变更、中断、中止或终止本服务，平台不因此而向您承担任何维权或侵权责任。</p>

		<h3>五、免责条款</h3>
		<p>
			1、您理解并同意，本服务是按照现有技术和条件所能达到的现状提供的，平台会尽最大努力向您提供本服务，确保服务的连贯性和安全性。但您亦知悉并认可，平台不能随时或始终预见和防范技术及其他风险，包括但不限于因不可抗力、网络原因、第三方服务瑕疵等原因可能导致的服务中断或异常等，如发生此类情形，平台将近最大商业努力予以改善，但无义务就此对您或其他第三方承担任何法律责任。
		</p>
		<p>2、平台可能自行进行停机维护、系统升级及调整，如因此而导致您无法正常使用本服务，您同意平台无需承担法律责任。</p>
		<p>3、在任何情况下，平台均不承担任何间接性、惩罚性、偶然性或刑罚性的损害赔偿责任。且平台对您承担的全部责任，无论因何原因或何种方式，始终不超过您因使用本而支付的费用。</p>


		<h3>六、违约责任</h3>
		<p>1、如您违反本协议，平台有权根据法律法规、相关协议及平台管理规定，视您行为的具体情形及危害程度，对您或您的账号采取管理措施，包括但不限于警告、扣除或清空您账号内的“糖果”、限制您账号全部或部分功能、短期或永久封禁您的账号等。
		</p>
		<p>
			2、您理解并认可，如有第三方涉嫌利用您的账号实施违法违规或违反本协议约定的行为，为了保障潜在受害人及其他用户合法权益、维护平台生态秩序并防止损害进一步扩大，平台亦有权按照本协议及平台相关规则对您的账号采取管理措施，对此您不应要求平台承担法律责任。
		</p>
		<p>
			3、如您在平台外实施或涉嫌实施了违法或不当行为，使平台有合理理由认为您已经或即将违反本协议或可对您使用的平台其他规则（如UniLive用户服务协议、社区自律公约等），平台亦有权对您采取本条第1款约定的管理措施，对此您不应要求平台承担法律责任。
		</p>



	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/RechargeAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/RechargeAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/RechargeAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/RechargeAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/RechargeAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/RechargeAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/RechargeAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/RechargeAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/RechargeAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/RechargeAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/RechargeAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/RechargeAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>